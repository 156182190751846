<template>
  <div>
    <a-modal
      :visible="visible.modalAddGolongan"
      title="Buat Golongan Baru"
      okText="Buat"
      @ok="handleCreateGolongan"
      @cancel="handleCancel"
      :okButtonProps="{
        props: {
          disabled: loading.add,
        },
      }"
      :cancelButtonProps="{
        props: {
          disabled: loading.add,
        },
      }"
    >
      <div
        v-if="loading.add"
        style="
          display: flex;
          justify-content: center;
          height: 30vh;
          align-items: center;
        "
      >
        <a-spin>
          <a-icon
            slot="indicator"
            type="loading"
            style="font-size: 5em; color: #008080"
            spin
          />
        </a-spin>
      </div>
      <a-form
        v-else
        layout="horizontal"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 15 }"
      >
        <a-form-item label="Nama">
          <a-input
            :value="this.golonganName"
            :disabled="userAdmin.finance"
            :placeholder="
              userAdmin.hr ? 'Nama Golongan' : 'bisa di input di admin hr'
            "
            @change="(e) => handleAddGolonganChange(e.target.value, 'nama')"
          />
        </a-form-item>
        <a-form-item label="Gaji Pokok">
          <a-input
            :value="this.golonganSalary"
            :disabled="userAdmin.hr"
            :placeholder="
              userAdmin.finance
                ? 'Gaji Pokok Golongan'
                : 'bisa di input di admin finance'
            "
            @change="(e) => handleAddGolonganChange(e.target.value, 'gapok')"
          />
        </a-form-item>
      </a-form>
    </a-modal>
    <a-modal
      :visible="visible.modalEditGolongan"
      :confirm-loading="loading.confirm"
      @ok="handleOkEditGolongan"
      @cancel="handleCancel"
      :maskStyle="{
        opacity: 0.5,
        backgroundColor: 'black',
      }"
      okText="Perbaharui"
      :okButtonProps="{
        props: {
          shape: 'round',
        },
      }"
      :cancelButtonProps="{
        props: {
          shape: 'round',
        },
      }"
    >
      <h3 slot="title" style="color: #faad14">
        <a-icon style="font-size: 1.2em" type="warning" /> KONFIRMASI EDIT
        GOLONGAN
      </h3>
      <p>
        Apakah anda yakin mau memperbarui nama golongan ini? Setelah diperbarui,
        data lama golongan ini akan digantikan dengan data yang baru.
      </p>
    </a-modal>
    <div class="d-flex align-items center">
      <div class="mr-3">
        <a-input
          v-model="golonganSearch"
          @keyup="searchGolongan"
          size="large"
          style="width: 320px"
          :placeholder="`Cari Nama / Gaji Pokok Golongan...`"
        />
      </div>
      <div class="ml-auto">
        <template v-if="!edit.golongan">
          <a-button
            v-if="!userAdmin.finance"
            class="mr-3"
            size="large"
            type="primary"
            @click.prevent="showModalAddGolongan(true)"
            icon="plus"
            >Tambah</a-button
          >
          <a-button size="large" @click.prevent="toEditGolongan" icon="edit"
            >Ubah</a-button
          >
        </template>
        <template v-else>
          <a-button
            @click.prevent="cancelEdit"
            :loading="loading.cancel"
            type="danger"
            size="large"
            icon="close"
            class="mb-3 mb-lg-0 mr-0 mr-md-3"
            >Batal</a-button
          >
          <a-button
            size="large"
            @click.prevent="saveEdit"
            icon="save"
            style="background-color: #41b883; color: white"
            >Simpan</a-button
          >
        </template>
      </div>
    </div>
    <div class="mt-4">
      <a-table
        :columns="golonganColumns"
        :data-source="golonganDataTable"
        :loading="loading.table"
        :pagination="pagination"
        @change="handleTableChangeGolongan"
        bordered
      >
        <div
          v-if="edit.golongan && userAdmin.hr"
          slot="nama"
          slot-scope="text, record"
        >
          <a-input
            :value="text"
            style="height: 50px; width: 100%"
            @change="
              (e) =>
                handleEditGolonganChange(e.target.value, record.key, 'nama')
            "
          />
        </div>
        <div v-else slot="nama" slot-scope="text">
          <span>{{ text }}</span>
        </div>
        <div
          v-if="edit.golongan && userAdmin.finance"
          slot="gapok"
          slot-scope="text, record"
        >
          <a-input
            :value="text"
            style="height: 50px; width: 100%"
            @change="
              (e) =>
                handleEditGolonganChange(e.target.value, record.key, 'gapok')
            "
          />
        </div>
        <div v-else slot="gapok" slot-scope="text">
          <span>{{ text }}</span>
        </div>
        <div slot="aksi" slot-scope="text, record">
          <a-button
            :disabled="edit.golongan || userAdmin.finance"
            size="large"
            class="text-danger border border-danger"
            :value="text"
            :loading="loading.delete"
            @click.prevent="(e) => deleteGolongan(e.target.value, record.key)"
            icon="delete"
            >Hapus</a-button
          >
        </div>
      </a-table>
    </div>
  </div>
</template>

<script>
const golonganColumns = [
  {
    title: 'No',
    dataIndex: 'no',
    key: 'no',
    scopedSlots: { customRender: 'no' },
    width: '8%',
    align: 'center',
    sorter: true,
  },
  {
    title: 'Nama',
    dataIndex: 'nama',
    key: 'nama',
    scopedSlots: { customRender: 'nama' },
    align: 'center',
    sorter: true,
    width: '30%',
  },
  {
    title: 'Gaji Pokok',
    dataIndex: 'gapok',
    key: 'gapok',
    scopedSlots: { customRender: 'gapok' },
    align: 'center',
    sorter: true,
    width: '50%',
  },
  {
    title: 'Aksi',
    dataIndex: 'aksi',
    key: 'aksi',
    scopedSlots: { customRender: 'aksi' },
    width: '12%',
    align: 'center',
  },
]

export default {
  name: 'master-golongan',
  data() {
    return {
      golonganColumns,
      golonganDataTable: [],
      savedGolonganData: [],
      visible: {
        modalAddGolongan: false,
        modalEditGolongan: false,
      },
      loading: {
        table: false,
        add: false,
        delete: false,
        cancel: false,
        confirm: false,
      },
      pagination: {},
      golonganPage: 'all',
      golonganSearch: '',
      golonganOrder: '',
      golonganSortBy: '',
      golonganName: null,
      golonganSalary: null,
      edit: {
        golongan: false,
      },
      userAdmin: {
        finance: false,
        hr: false,
      },
    }
  },
  methods: {
    handleEditGolonganChange(value, key, column) {
      const newData = [...this.golonganDataTable]
      const target = newData.filter((item) => key === item.key)[0]
      if (target) {
        target[column] = value
        this.savedGolonganData = newData
      }
    },
    handleTableChangeGolongan(pagination, filters, sorter) {
      // console.log('sorter', sorter)
      const pager = { ...this.pagination }
      pager.current = pagination.current
      this.pagination = pager
      let order = ''
      if (sorter.order === 'ascend') {
        order = 'ASC'
      } else if (sorter.order === 'descend') {
        order = 'DESC'
      } else {
        order = 'ASC'
      }
      let sortBy = ''
      if (sorter.field === 'nama') {
        sortBy = 'nama'
      } else if (sorter.field === 'gapok') {
        sortBy = 'gaji_pokok'
      } else {
        sortBy = ''
      }
      this.fetchDataGolongan({
        page: pagination.current,
        order,
        sortBy,
      })
    },
    handleAllLoading(boolean) {
      this.loading.table = boolean
      this.loading.add = boolean
      this.loading.delete = boolean
      this.loading.cancel = boolean
    },
    showModalAddGolongan(boolean) {
      this.visible.modalAddGolongan = boolean
    },
    searchGolongan() {
      if (this.golonganSearch.length > 2) {
        this.fetchDataGolongan({
          search: this.golonganSearch,
        })
      } else {
        this.fetchDataGolongan({
          search: this.golonganSearch,
        })
      }
    },
    fetchDataGolongan(
      params = {
        order: this.golonganOrder,
        sortBy: this.golonganSortBy,
        search: this.golonganSearch,
        page: this.golonganPage,
      },
    ) {
      this.handleAllLoading(true)
      this.$store
        .dispatch('adminHR/GET_GOLONGAN_BY_SITUATION', {
          order: params.order,
          sortBy: params.sortBy,
          search: params.search,
          page: params.page,
        })
        .then((res) => {
          // console.log('res', res)
          const pagination = { ...this.pagination }
          pagination.total = res.total
          this.pagination = pagination
          let no = 1
          this.golonganDataTable = res.data.map((row) => {
            return {
              key: row.id,
              no: no++,
              nama: row.nama,
              gapok: row.gaji_pokok,
            }
          })
          this.savedGolonganData = this.golonganDataTable
          this.handleAllLoading(false)
        })
    },
    handleCancel() {
      this.visible.modalAddGolongan = false
      this.visible.modalEditGolongan = false
    },
    handleAddGolonganChange(value, column) {
      if (column === 'nama') {
        this.golonganName = value
      }
      if (column === 'gapok') {
        this.golonganSalary = value
      }
    },
    handleCreateGolongan(value) {
      if (this.userAdmin.hr && (this.golonganName === '' || this.golonganName === null)) {
        this.$notification.error({
          message: 'Error',
          description: 'Harap mengisi nama golongan! Nama Golongan tidak boleh kosong!',
        })
        this.visible.modalAddGolongan = false
      } else if (this.userAdmin.finance && (this.golonganSalary === '' || this.golonganSalary === null)) {
        this.$notification.error({
          message: 'Error',
          description: 'Harap mengisi gaji golongan! Gaji Golongan tidak boleh kosong!',
        })
        this.visible.modalAddGolongan = false
      } else {
        this.handleAllLoading(true)
        this.$store
          .dispatch('adminHR/CREATE_GOLONGAN', {
            data: {
              nama: this.golonganName,
              gapok: this.golonganSalary,
            },
          })
          .then(
            (
              params = {
                page: 'all',
                order: 'ASC',
                sortBy: '',
                search: '',
              },
            ) => {
              return this.$store
                .dispatch('adminHR/GET_GOLONGAN_BY_SITUATION', {
                  order: params.order,
                  sortBy: params.sortBy,
                  search: params.search,
                  page: params.page,
                })
                .then((res) => {
                  // console.log('res', res)
                  const pagination = { ...this.pagination }
                  pagination.total = res.total
                  this.pagination = pagination
                  let no = 1
                  this.golonganDataTable = res.data.map((row) => {
                    return {
                      key: row.id,
                      no: no++,
                      nama: row.nama,
                      gapok: row.gaji_pokok,
                    }
                  })
                  this.savedGolonganData = this.golonganDataTable
                  this.handleAllLoading(false)
                  this.visible.modalAddGolongan = false
                  setTimeout(() => {
                    this.$notification.success({
                      message: 'Sukses',
                      description: `Berhasil menambah golongan baru ${this.golonganName}`,
                    })
                  })
                  this.golonganName = null
                  this.golonganSalary = null
                })
            },
          )
      }
    },
    deleteGolongan(value, key) {
      this.handleAllLoading(true)
      this.$confirm({
        title: 'Peringatan!!',
        content: (
          <div>
            Apakah anda yakin ingin menghaspus golongan ini? Setelah menghapus,
            data golongan ini akan hilang!
          </div>
        ),
        onOk: () => {
          this.$store
            .dispatch('adminHR/DELETE_GOLONGAN', {
              id: key,
            })
            .then((res) => {
              this.handleAllLoading(false)
              if (res === 200) {
                this.$notification.success({
                  message: 'Sukses',
                  description: 'Golongan berhasil dihapus',
                })
              } else {
                this.$notification.error({
                  message: 'Error',
                  description: 'Golongan tidak berhasil dihapus',
                })
              }
              this.fetchDataGolongan()
            })
        },
        onCancel: () => {
          this.handleAllLoading(false)
        },
        centered: true,
        icon: 'warning',
        okType: 'danger',
        okText: 'Remove',
      })
    },
    toEditGolongan() {
      this.edit.golongan = true
    },
    saveEdit() {
      this.visible.modalEditGolongan = true
    },
    cancelEdit() {
      this.handleAllLoading(true)
      this.fetchDataGolongan()
      setTimeout(() => {
        this.edit.golongan = false
        this.handleAllLoading(false)
      })
    },
    validateGolonganDataInput(data) {
      let isInvalid = false
      let message = ''
      for (let i = 0; i < data.length; i++) {
        if (this.userAdmin.finance) {
          if (
            data[i].gapok === '' ||
            data[i].gapok === '-' ||
            data[i].gapok === null ||
            data[i].gapok === '0'
          ) {
            isInvalid = true
            message = `Maaf gaji pokok no. ${
              i + 1
            }, tidak boleh kosong atau 0!`
            break
          } else if (isNaN(Number(data[i].gapok))) {
            isInvalid = true
            message = `Maaf gaji pokok no. ${i + 1}, harus angka!`
            break
          }
        }
        if (this.userAdmin.hr) {
          if (
            data[i].nama === '' ||
            data[i].nama === '-' ||
            data[i].nama === null
          ) {
            isInvalid = true
            message = `Maaf nama, ${data[i].nama} tidak boleh kosong!`
            break
          }
        }
      }
      return {
        isInvalid,
        message,
      }
    },
    handleOkEditGolongan() {
      const newData = [...this.savedGolonganData]
      if (newData.length) {
        const validation = this.validateGolonganDataInput(newData)
        if (validation.isInvalid) {
          this.$notification.error({
            message: 'Sorry.',
            description: validation.message,
          })
          this.visible.modalEditGolongan = false
        } else {
          const target = newData.map((row) => {
            return {
              id: row.key,
              nama: row.nama,
              gaji_pokok: row.gapok,
            }
          })
          // console.log('Berhasil', target)
          this.confirmLoading = true
          this.$store
            .dispatch('adminHR/UPDATE_GOLONGAN', {
              data: target,
            })
            .then((res) => {
              setTimeout(() => {
                this.fetchDataGolongan()
                this.edit.golongan = false
                this.visible.modalEditGolongan = false
                this.handleAllLoading(false)
                if (res.isSuccess) {
                  this.$notification.success({
                    message: 'Sukses',
                    description: 'Golongan berhasil diperbarui',
                  })
                } else {
                  this.$notification.error(
                    {
                      message: 'Gagal',
                      description: 'Golongan tidak berhasil diperbarui',
                    },
                    1000,
                  )
                }
              })
            })
        }
      } else {
        this.edit.golongan = false
        this.visible.modalEditGolongan = false
        this.handleAllLoading(false)
        setTimeout(() => {
          this.$notification.error({
            message: 'Gagal',
            description: 'Golongan tidak berhasil diperbarui',
          })
        })
      }
    },
  },
  mounted() {
    this.fetchDataGolongan()
    if (this.user.role[0] === 'HR') {
      this.userAdmin.hr = true
    } else if (this.user.role[0] === 'keuangan') {
      this.userAdmin.finance = true
    } else {
      this.userAdmin.hr = false
      this.userAdmin.finance = false
    }
  },
  computed: {
    user() {
      return this.$store.state.user.user
    },
  },
}
</script>

<style lang="css">
.blackBorder {
  border: 1px solid black;
}
</style>
